<template>
  <div>
    <a-card :title="$t('入库报表')">
      <a-row :gutter="[12, 12]">
        <a-col :span="24" style="width: 256px">
          <a-range-picker @change="changeRangePicker" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-input-search v-model="searchForm.search" :placeholder="$t('产品编号, 名称')" allowClear @search="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-input-search v-model="searchForm.batch_number" :placeholder="$t('批次编号')" allowClear @search="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-input-search
            v-model="searchForm.receipt_order_number"
            :placeholder="$t('单据编号')"
            allowClear
            @search="search"
          />
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" @click="search" style="width: 100%">{{ $t("查询") }}</a-button>
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button icon="download" @click="exportData" style="width: 100%">{{ $t("导出") }}</a-button>
        </a-col>

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :dataSource="dataItems"
            :loading="dataLoading"
            :pagination="pagination"
            @change="changeTable"
          />
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { stockInReportList, stockInReportExport } from "@/api/report";
import { exportExcel } from "@/utils/excel";
import moment from "moment";

export default {
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 16 },
      dataLoading: false,

      // Table
      columns: [
        {
          title: this.$t("序号"),
          dataIndex: "index",
          customRender: (_value, _item, index) => index + 1,
        },
        {
          title: this.$t("入库通知单号"),
          dataIndex: "receipt_order_number",
          customRender: (_value, item) => item.receipt_order_item.number,
        },
        {
          title: this.$t("产品编号"),
          dataIndex: "material_number",
          customRender: (_value, item) => item.material_item.number,
        },
        {
          title: this.$t("产品名称"),
          dataIndex: "material_name",
          customRender: (_value, item) => item.material_item.name,
        },
        {
          title: this.$t("批号"),
          dataIndex: "batch_number",
        },
        {
          title: this.$t("入库数量"),
          dataIndex: "total_quantity",
          sorter: true,
        },
        {
          title: this.$t("单位"),
          dataIndex: "unit",
          customRender: (_value, item) => item.material_item.unit,
        },
        {
          title: this.$t("产品单价"),
          dataIndex: "unit_price",
          customRender: (_value, item) => item.unit_price,
        },
        {
          title: this.$t("创建时间"),
          dataIndex: "create_time",
          customRender: (_value, item) => item.receipt_order_item.create_time,
        },
      ],
      dataItems: [],
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      this.dataLoading = true;
      stockInReportList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    changeRangePicker(date) {
      let startDate, endDate;
      if (date.length === 2) {
        startDate = date[0].format("YYYY-MM-DD");
        endDate = date[0].format("YYYY-MM-DD");
        endDate = moment(endDate).add(1, "days").format("YYYY-MM-DD");
      }

      this.searchForm.start_date = startDate;
      this.searchForm.end_date = endDate;
      this.search();
    },
    exportData() {
      stockInReportExport(this.searchForm).then((resp) => {
        exportExcel(resp, this.$t("入库报表"));
      });
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
